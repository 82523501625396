export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52')
];

export const server_loads = [];

export const dictionary = {
		"/": [4],
		"/(private)/approval": [5,[2]],
		"/(private)/approval/manual": [6,[2]],
		"/(private)/articles": [7,[2]],
		"/(private)/articles/[id]/[store]/[delivery]": [8,[2]],
		"/(private)/back-bonus": [9,[2]],
		"/(private)/back-bonus/[custNo]/[storeNo]": [10,[2]],
		"/(private)/credit": [11,[2]],
		"/(private)/credit/[id]": [12,[2]],
		"/(private)/customers": [13,[2]],
		"/(private)/customers/branch/[store]/[customer]/[id]": [14,[2]],
		"/(private)/customers/branch/[store]/[customer]/[id]/editBranch": [15,[2]],
		"/(private)/customers/branch/[store]/[customer]/[id]/editDelivery": [16,[2]],
		"/(private)/customers/branch/[store]/[customer]/[id]/editOpenHours": [17,[2]],
		"/(private)/customers/contacts/[store]/[customer]": [18,[2]],
		"/(private)/customers/contacts/[store]/[customer]/edit": [20,[2]],
		"/(private)/customers/contacts/[store]/[customer]/newBranchContact/[branchId]": [21,[2]],
		"/(private)/customers/contacts/[store]/[customer]/[branchId]/[contactId]": [19,[2]],
		"/(private)/customers/detail/[store]/[customer]": [22,[2]],
		"/(private)/customers/edit/[store]/[customer]/[id]": [23,[2]],
		"/(private)/customers/preview/[store]/[customer]": [24,[2]],
		"/(private)/delivery-orders": [25,[2]],
		"/(private)/delivery-orders/new": [28,[2]],
		"/(private)/delivery-orders/[id]": [26,[2]],
		"/(private)/delivery-orders/[id]/edit": [27,[2]],
		"/(private)/individual-prices": [29,[2]],
		"/(private)/individual-prices/new": [31,[2]],
		"/(private)/individual-prices/[id]": [30,[2]],
		"/(public)/login": [52,[3]],
		"/(private)/mass-upload/individual-prices": [32,[2]],
		"/(private)/master-customer": [33,[2]],
		"/(private)/master-customer/new": [35,[2]],
		"/(private)/master-customer/[id]": [34,[2]],
		"/(private)/purchase-prices": [36,[2]],
		"/(private)/users": [37,[2]],
		"/(private)/users/detail/[id]": [38,[2]],
		"/(private)/users/edit/[id]": [39,[2]],
		"/(private)/users/new": [40,[2]],
		"/(private)/visit-schedule": [41,[2]],
		"/(private)/visit-schedule/approval": [42,[2]],
		"/(private)/visit-schedule/approval/[id]": [43,[2]],
		"/(private)/visit-schedule/approval/[id]/edit": [44,[2]],
		"/(private)/visit-schedule/dashboard": [45,[2]],
		"/(private)/visit-schedule/detail/[id]": [46,[2]],
		"/(private)/visit-schedule/detail/[id]/finish": [47,[2]],
		"/(private)/visit-schedule/detail/[id]/monitoring": [48,[2]],
		"/(private)/visit-schedule/edit/[id]": [49,[2]],
		"/(private)/visit-schedule/history/[id]": [50,[2]],
		"/(private)/visit-schedule/new/[type]": [51,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';